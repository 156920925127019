
const Loading=()=>{
    return(
    <>
    <div className='absolute'>
    <div className='loader'/>
    <h4>Please wait Cheng Yang Portfolio is Loading </h4>
    </div>

    </>
    )
}
export default Loading